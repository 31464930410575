import { AbilityBuilder } from "@casl/ability";
import { util } from "../api/util/util.jsx";

export default function defineRulesFor(user) {
  const { rules, can } = AbilityBuilder.extract();
  const userEmail = user?.sub;
  let authorities_values = util.getAuthoritiesValues(user.authorities);   
  // const adminEmail = "andre.botta@outcomex.com.au";
  const adminEmail = "marco.delgado@outcomex.com.au";
  if (userEmail === adminEmail){
    can ("edit", "dashboardAdminComments");
  }

  if (authorities_values.indexOf("super admin") >= 0) {
    can ("change", "projectPreviousFiscalYear");
    can("change", "mam_fy");
    can("see", "NSA Dashboard");
    can("create", "project");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("edit", "forecastingFields");
    can("see", "Users Management");
    can("see", "Tender Logins");
    can("see", "Commission Structure");
    can("edit", "Commission Structure");
    can("see", "all_projects");
    can("edit", "forecasting_existing_new_business");
    can("edit", "commission_percentage");
    can("see", "summary");
    can("see", "Changes History");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("delete", "project");
    can("see", "Customer Opportunity Heatmap");
    can("delete", "client");
    can("delete", "employee");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("edit", "customer name");
    can("edit", "customer_number");
    can("edit", "after received");
    can("see", "mam totals");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("see", "Suppliers/Distributors");
    can("see", "Vendors");
    can("edit", "invoice_status");
    can("delete", "tenderLogin");
    can("changeUser", [
      "commissionAchieved",
      "oppportunitiesPerCustomer",
      "nonQuotedOpportunities",
      "summaryOfPerformance",
      "quotesLost",
      "openQuotes"
    ]);
    can("see", "mamSPecialFilters");
    can("create", "client");
    can("edit", "commission");
    can("see", "Customer Financial Summary");
    can("see", "Engineer Profitability");
    can("see", "Annuity Renewals");
    can("see", "Fiscal Year");
    can("delete", "vendor");
    can("edit", "priorityRating");
    can("edit", "engineerCurrentYearExpenses");
    can("see", "runSalaryGate");
    can("edit", "eligibleBonus");
    can("see", "Dashboard");
    can("see", "Account Manager Profitability");
    can("edit", "accountManagerExpensesSalary");
    can("setProjectTo", "allStages");
    can("edit", "recurringEntryQuarter");
    can("see", "allNonQuotedOpportunities");
    can("see", "Negative Gross Profit");
    can("edit", "annualSalesTarget");
    can("edit", "otherInvestments");
    can("see", "Create Quote");
    can("see", "Renewals");
    can("override", "grossProfit");
    can("see", "Expenses");
    can("see", "Expensesv2");
    can("see", "Invoices");
    can("see", "Purchase Orders");
    can("edit", "expenses");
    can("change", "checkedStateMRE");
    can("changeUser", "NSADashboard");
    can("edit", "quoteElementRenewal");
    can("see","Users Activity");

  }
  if (authorities_values.indexOf("admin") >= 0) {
    can ("change", "projectPreviousFiscalYear");
    can("see", "NSA Dashboard");
    can("change", "mam_fy");
    can("create", "project");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("edit", "forecastingFields");
    can("see", "Users Management");
    can("see", "Commission Structure");
    can("edit", "Commission Structure");
    can("see", "all_projects");
    can("edit", "forecasting_existing_new_business");
    can("edit", "commission_percentage");
    can("see", "summary");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("delete", "project");
    can("see", "Customer Opportunity Heatmap");
    can("delete", "client");
    can("delete", "employee");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("edit", "customer name");
    can("edit", "customer_number");
    can("edit", "after received");
    can("see", "mam totals");
    can("see", "Changes History");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("edit", "invoice_status");
    can("see", "Tender Logins");
    can("delete", "tenderLogin");
    can("changeUser", [
      "commissionAchieved",
      "oppportunitiesPerCustomer",
      "nonQuotedOpportunities",
      "summaryOfPerformance",
      "quotesLost",
      "openQuotes"
    ]);
    can("create", "client");
    can("edit", "commission");
    can("see", "Customer Financial Summary");
    can("see", "Engineer Profitability");
    can("see", "Annuity Renewals");
    can("see", "Suppliers/Distributors");
    can("see", "Vendors");
    can("delete", "vendor");
    can("edit", "priorityRating");
    can("edit", "engineerCurrentYearExpenses");
    can("see", "runSalaryGate");
    can("edit", "eligibleBonus");
    can("see", "Dashboard");
    can("see", "Account Manager Profitability");
    can("edit", "accountManagerExpensesSalary");
    can("setProjectTo", "allStages");
    can("edit", "recurringEntryQuarter");
    can("see", "allNonQuotedOpportunities");
    can("see", "Negative Gross Profit");
    can("edit", "annualSalesTarget");
    can("edit", "otherInvestments");
    can("see", "Create Quote");
    can("see", "Renewals");
    can("override", "grossProfit");
    can("see", "Expenses");
    can("see", "Expensesv2");
    can("see", "Invoices");
    can("see", "Purchase Orders");
    can("edit", "expenses");
    can("changeUser", "NSADashboard");
    can("edit", "quoteElementRenewal");
    can("see","Users Activity");
  }

  if (authorities_values.indexOf("engineering admin") >= 0) {
    can("create", "project");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("edit", "forecastingFields");
    can("see", "all_projects");
    can("edit", "forecasting_existing_new_business");
    can("see", "summary");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("edit", "customer name");
    can("see", "mam totals");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("edit", "invoice_status");
    can("see", "Tender Logins");
    can("changeUser", [
      "commissionAchieved",
      "oppportunitiesPerCustomer",
      "nonQuotedOpportunities",
      "summaryOfPerformance",
      "quotesLost",
      "openQuotes"
    ]);
    can("see", "Customer Financial Summary");
    can("see", "Engineer Profitability");
    can("see", "Suppliers/Distributors");
    can("see", "Vendors");
  }

  if (authorities_values.indexOf("national sales director") >= 0) {
    can("see", "NSA Dashboard");
    can("change", "mam_fy");
    can("edit", "annualSalesTarget");
    can("see", "Account Manager Profitability");
    can("see", "Dashboard");
    can("see", "mam totals");
    can("create", "project");
    can("edit", "project");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("see", "Users Management");
    can("see", "Tender Logins");
    can("edit", "forecastingFields");
    can("see", "all_projects");
    can("edit", "forecasting_existing_new_business");
    can("see", "Commission Structure");
    can("edit", "Commission Structure");
    can("edit", "projectStatus");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("see", "Vendor Account Manager");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("changeUser", [
      "commissionAchieved",
      "oppportunitiesPerCustomer",
      "nonQuotedOpportunities",
      "summaryOfPerformance",
      "quotesLost",
      "openQuotes"
    ]);
    can("create", "client");
    can("edit", "commission");
    can("see", "Changes History");
    can("see", "Customer Financial Summary");
    can("see", "Engineer Profitability");
    can("edit", "commission_percentage");
    can("see", "runSalaryGate");
    can("edit", "eligibleBonus");
    can("see", "allNonQuotedOpportunities");
    can("see", "Create Quote");
    can("see", "Renewals");
    can("see", "Expenses");
    can("see", "Expensesv2");
    can("see", "Invoices");
    can("see", "Purchase Orders");
    can("changeUser", "NSADashboard");
  }

  if (authorities_values.indexOf("presales architect") >= 0) {

    can("see", ["Non Quoted Opportunities"]);
    can("changeUser", [
      "nonQuotedOpportunities"
    ]);
    can("see", "allNonQuotedOpportunities");
  }

if (authorities_values.indexOf("sales manager NSW") >= 0) {
  can("see", "NSA Dashboard");
  can("change", "mam_fy");
  can("changeUser", "NSADashboard");
  can("setTo", "lost");
  can("setTo", "lost_duplicate");
  can("edit", "lostStage");
  can("edit", "forecastingFields");
  can("see", "MAM");
  can("see", "Tender Logins");
  can("see", "Non Quoted Opportunities");
  can("see", "Quotes Lost");
  can("see", "Open Quotes");
  can("see", "comments");
  can("see", "Customer Opportunity Heatmap");
  can("see", "Summary of Performance");
  can("see", "Commission Summary");
  can("see", "Vendor Account Manager");
  can("see", "Vendors/Equipment Makers");
  can("see", "Deal ID Registration Process");
  can("see", "Vendor Deal-ID Discounts");
  can("delete", "customerDiscount");
  can("see", "MAM");
  can("see", "Tender Logins");
  can("see", "Non Quoted Opportunities");
  can("see", "Quotes Lost");
  can("see", "Open Quotes");
  can("see", "comments");
  can("see", "Customer Opportunity Heatmap");
  can("see", "Summary of Performance");
  can("see", "Commission Summary");
  can("see", "Vendor Account Manager");
  can("see", "Vendors/Equipment Makers");
  can("see", "Deal ID Registration Process");
  can("see", "Vendor Deal-ID Discounts");
  can("delete", "customerDiscount");
  can("see", "Account Manager Profitability");  
  can("see", "Customer Financial Summary");
  can("changeUser", [
    "commissionAchieved",
    "oppportunitiesPerCustomer",
    "nonQuotedOpportunities",
    "summaryOfPerformance",
    "quotesLost",
    "openQuotes"
  ]);
  can("changeUser", "restrictedTo", ["nsw"]);
  can("see", "Create Quote");
  can("see", "Renewals");
  can("see", "all_projects");
}


  
if (authorities_values.indexOf("sales manager victoria") >= 0) {
    can("see", "NSA Dashboard");
    can("change", "mam_fy");
    can("changeUser", "NSADashboard");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("edit", "lostStage");
    can("edit", "forecastingFields");
    can("see", "MAM");
    can("see", "Tender Logins");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "comments");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("see", "Vendor Account Manager");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("see", "MAM");
    can("see", "Tender Logins");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "comments");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("see", "Vendor Account Manager");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("see", "Customer Financial Summary");
    can("changeUser", [
      "commissionAchieved",
      "oppportunitiesPerCustomer",
      "nonQuotedOpportunities",
      "summaryOfPerformance",
      "quotesLost",
      "openQuotes"
    ]);
    can("changeUser", "restrictedTo", ["vic,sa"]);
    can("see", "Create Quote");
    can("see", "Renewals");
    can("see", "all_projects");
  }

  if (authorities_values.indexOf("sales manager sa") >= 0) {
    can("setTo", "lost");
    can("change", "mam_fy");
    can("setTo", "lost_duplicate");
    can("edit", "lostStage");
    can("edit", "forecastingFields");
    can("see", "MAM");
    can("see", "Tender Logins");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "comments");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("see", "Vendor Account Manager");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("see", "MAM");
    can("see", "Tender Logins");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "comments");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("see", "Vendor Account Manager");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");

    can("changeUser", [
      "commissionAchieved",
      "oppportunitiesPerCustomer",
      "nonQuotedOpportunities",
      "summaryOfPerformance",
      "quotesLost",
      "openQuotes"
    ]);
    can("changeUser", "restrictedTo", ["sa"]);
    can("see", "Create Quote");
    can("see", "Renewals");
    // can("changeUser", "commissionAchievedRestricted"); // not ideal, refactor
  }

  if (authorities_values.indexOf("managed services admin") >= 0) {
    can("see", "MAM");
    can("edit", "customer_number");
    can("edit", "after received");
    can("create", "client");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("create", "project");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("see", "all_projects");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "mam totals");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("edit", "invoice_status");
    can("edit", "after received");
    can("create", "client");
    can("see", "Tender Logins");
  }

  if (authorities_values.indexOf("managed services practice lead") >= 0) {
    can("see", "Annuity Renewals");
    can("see", "Create Quote");
    can("see", "Renewals");
  }

  if (authorities_values.indexOf("renewals manager") >= 0) {
    can("create", "project");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");

    can("see", "all_projects");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "mam totals");
    can("see", "MAM");
    can("see", "Annuity Renewals");
    can("see", "MAM");
    can("see", "Tender Logins");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "comments");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("see", "Vendor Account Manager");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("changeUser", ["oppportunitiesPerCustomer"]);
    can("see", "Renewals");
    can("edit", "quoteElementRenewal");
  }

  if (authorities_values.indexOf("marketing coordinator") >= 0) {
    can("create", "project");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("change", "mam_fy");
    can("see", "all_projects");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "mam totals");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("edit", "invoice_status");
    can("edit", "after received");
    can("create", "client");
    can("see", "Tender Logins");
    can("see", "Create Quote");
    can("see", "Renewals");
    can("see", "Dashboard");
  }
  if (authorities_values.indexOf("inside sales") >= 0) {
    can("create", "project");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("change", "mam_fy");
    can("see", "all_projects");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "mam totals");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("edit", "invoice_status");
    can("edit", "after received");
    can("create", "client");
    can("see", "Tender Logins");
    can("see", "Create Quote");
    can("see", "Renewals");
    
  }

  if (authorities_values.indexOf("accounts admin") >= 0) {
    can ("change", "projectPreviousFiscalYear");
    can("see", "NSA Dashboard");
    can("change", "mam_fy");
    can("edit", "annualSalesTarget");
    can("create", "project");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    //  can("see", "Tender Logins");
    can("see", "all_projects");
    can("see", "Commission Structure");
    can("edit", "Commission Structure");
    can("see", "summary");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "Users Management");
    can("delete", "project");
    can("edit", "customer_number");
    can("edit", "after received");
    can("see", "mam totals");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("edit", "invoice_status");
    can("create", "client");
    can("see", "Commission Summary");
    can("edit", "commission");
    can("see", "Changes History");
    can("see", "Customer Financial Summary");
    can("delete", "client");
    can("see", "Suppliers/Distributors");
    can("see", "Vendors");
    can("delete", "vendor");

    can("see", "Tender Logins");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Vendor Account Manager");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");

    can("see", "Customer Financial Summary");
    can("see", "Engineer Profitability");
    can("see", "Annuity Renewals");

    can("changeUser", [
      "commissionAchieved",
      "oppportunitiesPerCustomer",
      "nonQuotedOpportunities",
      "summaryOfPerformance",
      "quotesLost",
      "openQuotes"
    ]);

    can("edit", "priorityRating");
    can("edit", "engineerCurrentYearExpenses");
    can("edit", "commission_percentage");

    can("see", "runSalaryGate");
    can("edit", "eligibleBonus");
    can("see", "Dashboard");
    can("see", "Account Manager Profitability");
    can("edit", "accountManagerExpensesSalary");
    can("setProjectTo", "allStages");
    can("edit", "recurringEntryQuarter");
    can("see", "allNonQuotedOpportunities");
    can("see", "Negative Gross Profit");
    can("see", "Create Quote");
    can("see", "Renewals");
    can("override", "grossProfit");
    can("see", "Invoices");
    can("see", "Expenses");
    can("see", "Expensesv2");
    can("see", "Purchase Orders");
    can("edit", "expenses");
    can("change", "checkedStateMRE");
    can("changeUser", "NSADashboard");
    can("edit", "quoteElementRenewal");
  }

  if (authorities_values.indexOf("accounts") >= 0) {
    can("create", "project");
    can("change", "mam_fy");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("delete", "project");
    can("setProjectTo", "allStages");
    //  can("see", "Tender Logins");
    can("see", "all_projects");
    can("see", "summary");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "mam totals");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    // can("see", "Users Management");
    can("edit", "invoice_status");
    can("edit", "after received");
    can("create", "client");
    can("edit", "customer_number");
    can("see", "Negative Gross Profit");
    can("see", "Create Quote");
    can("see", "Renewals");
    can("override", "grossProfit");    
    can("see", "Invoices");
    can("see", "Purchase Orders");
  }
  if (authorities_values.indexOf("staging stores manager") >= 0) {
    can("create", "project");
    can("change", "mam_fy");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("delete", "project");
    can("setProjectTo", "allStages");
    //  can("see", "Tender Logins");
    can("see", "all_projects");
    can("see", "summary");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "mam totals");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    // can("see", "Users Management");
    can("edit", "invoice_status");
    can("edit", "after received");
    can("create", "client");
    can("edit", "customer_number");
    can("see", "Negative Gross Profit");
    can("see", "Create Quote");
    can("see", "Renewals");
    can("override", "grossProfit");
    can("see", "Expenses");
    can("see", "Expensesv2");
    can("see", "Invoices");
    can("see", "Purchase Orders");
  }

  // if (authorities_values.indexOf("sales admin") >= 0) {
  //   can("create", "project");
  //   can("edit", "project");
  //   can("edit", "quoteStage");
  //   can("edit", "lostStage");
  //   can("edit", "wonStage");
  //   can("edit", "receivedStage");
  //   can("see", "MAM");
  //   can("see", "Tender Logins");
  //   can("edit", "forecastingFields");
  //   can("see", "all_projects");
  //   can("edit", "forecasting_existing_new_business");
  //   can("see", "Commission Structure");
  //   can("edit", "Commission Structure");
  //   can("edit", "projectStatus");
  //   can("see", "comments");
  //   can("setTo", "opened");
  //   can("setTo", "lost");
  //   can("setTo", "lost_duplicate");
  //   can("setTo", "won");
  //   can("setTo", "won_3_months");
  //   can("setTo", "received");
  //   can("see", "Non Quoted Opportunities");
  //   can("see", "Quotes Lost");
  //   can("see", "Open Quotes");
  //   can("see", "Customer Opportunity Heatmap");
  //   can("see", "Summary of Performance");
  //   can("see", "Commission Summary");
  //   can("see", "Vendor Account Manager");
  //   can("see", "Vendors/Equipment Makers");
  //   can("see", "Deal ID Registration Process");
  //   can("see", "Vendor Deal-ID Discounts");
  //   can("delete", "customerDiscount");
  //   can("create", "client");
  // }

  if (authorities_values.indexOf("sales") >= 0) {
    can("see", "NSA Dashboard");
    can("change", "mam_fy");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("edit", "lostStage");
    can("edit", "forecastingFields");
    can("see", "MAM");
    can("see", "Tender Logins");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "comments");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("see", "Vendor Account Manager");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("see", "Create Quote");
    can("see", "Renewals");
  }
  if (authorities_values.indexOf("sales and account admin") >= 0) {
    can("see", "NSA Dashboard");
    can("change", "mam_fy");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("edit", "lostStage");
    can("edit", "forecastingFields");
    can("see", "MAM");
    can("see", "Tender Logins");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "comments");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");    
    can("see", "Vendor Account Manager");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("see", "Create Quote");
    can("see", "Renewals");
    can ("change", "projectPreviousFiscalYear"); 
    can("edit", "annualSalesTarget");
    can("create", "project");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");   
    can("edit", "wonStage");
    can("edit", "receivedStage");    
    can("see", "all_projects");   
    can("see", "summary");   
    can("setTo", "opened"); 
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");   
    can("delete", "project");
    can("edit", "customer_number");
    can("edit", "after received");
    can("see", "mam totals");    
    can("delete", "customerDiscount");
    can("edit", "invoice_status");
    can("create", "client");     
    can("see", "Customer Financial Summary");
    can("delete", "client");
    can("see", "Suppliers/Distributors");
    can("see", "Vendors");
    can("delete", "vendor");
    can("delete", "customerDiscount");
    can("see", "Customer Financial Summary"); 
    can("changeUser", [
      "commissionAchieved",
      "oppportunitiesPerCustomer",
      "nonQuotedOpportunities",
      "summaryOfPerformance",
      "quotesLost",
      "openQuotes"
    ]);
    can("edit", "priorityRating");    
    can("edit", "commission_percentage");
    can("see", "runSalaryGate");
    can("edit", "eligibleBonus");   
    can("setProjectTo", "allStages");
    can("edit", "recurringEntryQuarter");
    can("see", "allNonQuotedOpportunities"); 
    can("override", "grossProfit");    
    can("change", "checkedStateMRE");
    can("changeUser", "NSADashboard");
    can("edit", "quoteElementRenewal");
  }

  if (authorities_values.indexOf("accounts") >= 0) {
    can("create", "project");
    can("change", "mam_fy");
    can("edit", "project");
    can("edit", "projectStatus");
    can("edit", "quoteStage");
    can("edit", "lostStage");
    can("edit", "wonStage");
    can("edit", "receivedStage");
    can("see", "MAM");
    can("delete", "project");
    can("setProjectTo", "allStages");
    //  can("see", "Tender Logins");
    can("see", "all_projects");
    can("see", "summary");
    can("see", "comments");
    can("setTo", "opened");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("setTo", "won");
    can("setTo", "won_3_months");
    can("setTo", "received");
    can("setTo", "paid_closed");
    can("see", "mam totals");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    // can("see", "Users Management");
    can("edit", "invoice_status");
    can("edit", "after received");
    can("create", "client");
    can("edit", "customer_number");
    can("see", "Negative Gross Profit");
    can("see", "Create Quote");
    can("see", "Renewals");
    can("override", "grossProfit");
    can("see", "Expenses");
    can("see", "Expensesv2");
    can("see", "Invoices");
    can("see", "Purchase Orders");
  }
  if (authorities_values.indexOf("mam-readonly") >= 0) {
    can("see", "MAM");
    can("see", "all_projects");
  }

  if (authorities_values.indexOf("project manager") >= 0) {
    can("see", "MAM");
    can("see", "all_projects");
  }

  if (authorities_values.indexOf("project manager admin") >= 0) {
    can("see", "MAM");
    can("see", "all_projects");
    can("edit", "wonStage");

  }

  if (authorities_values.indexOf("security practice lead") >= 0) {
    can("changeUser", [
      "openQuotes",
    ]);
  }

  if (authorities_values.indexOf("security practice lead") >= 0 ||
    authorities_values.indexOf("data centre practice lead") >= 0 ||
    authorities_values.indexOf("collaboration practice lead") >= 0) {
    can("changeUser", [
      "oppportunitiesPerCustomer",
    ]);
  }



  let isPracticeLead = false;
  authorities_values.forEach(function (element) {
    if (element.includes("practice lead")) {
      isPracticeLead = true;
    }
  });

  if (isPracticeLead || authorities_values.indexOf("principle architect") >= 0) {
    can("see", "NSA Dashboard");
    can("setTo", "lost");
    can("setTo", "lost_duplicate");
    can("edit", "lostStage");
    can("edit", "forecastingFields");
    can("see", "MAM");
    can("see", "Tender Logins");
    can("see", "Non Quoted Opportunities");
    can("see", "Quotes Lost");
    can("see", "Open Quotes");
    can("see", "comments");
    can("see", "Customer Opportunity Heatmap");
    can("see", "Summary of Performance");
    can("see", "Commission Summary");
    can("see", "Vendor Account Manager");
    can("see", "Vendors/Equipment Makers");
    can("see", "Deal ID Registration Process");
    can("see", "Vendor Deal-ID Discounts");
    can("delete", "customerDiscount");
    can("see", "Create Quote");
    can("see", "Renewals");
  }

  if (authorities_values.indexOf("principle architect") >= 0){
    can("see", "MAM");
    can("see", "all_projects");
    can("see", "mam totals");
    can("changeUser", "NSADashboard");
  }


  if (authorities_values.indexOf("security practice lead") >= 0) {
    can("see", "all_projects");
  }

  return rules; // <------------- !!!!
}
